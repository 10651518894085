export const EMPTY_ARRAY: readonly [] = Object.freeze([]);
export const EMPTY_OBJECT: Readonly<Record<string, never>> = Object.freeze({});

export function delayMs(ms: number): Promise<void> {
	return new Promise(resolve => { setTimeout(resolve, ms) });
}

export function identity<T>(v: T): T {
	return v;
}

export function noop(): void { /* Intentionally empty */ }

export function fmt(lits: TemplateStringsArray, ...values: unknown[]): string {
	let out: any[] = [];
	for (let i = 0; ; i++) {
		out.push(lits[i]);

		if (i >= values.length) break;
		let v = values[i];

		let type = typeof v;
		switch (type) {
		case "bigint":
		case "boolean":
		case "number":
		case "symbol":
		case "undefined":
			out.push(v);
			break;
		case "object":
		case "string":
			out.push(JSON.stringify(v));
			break;
		case "function":
			// eslint-disable-next-line @typescript-eslint/ban-types
			out.push(`[function ${(v as Function).name}]`);
			break;
		default:
			exhaustive(type);
			out.push(JSON.stringify(v));
		}
	}

	return out.join("");
}

class InterpolatedError extends Error {
	constructor(
		message: string,
		readonly values: unknown[],
	) {
		super(message);
	}
}

export function err(lits: TemplateStringsArray, ...values: unknown[]): Error {
	return new InterpolatedError(fmt(lits, ...values), values);
}

export function exhaustive(unexpected: never): never {
	throw err`Unexpected value ${unexpected}`;
}

interface TypeOfTags {
	bigint: bigint,
	boolean: boolean,
	function: Function, // eslint-disable-line @typescript-eslint/ban-types
	number: number,
	object: object | null,
	string: string,
	symbol: symbol,
	undefined: undefined,
}

/** Check if `obj` has a property `prop` of type `type`.
 *
 * Effectively checks of `typeof obj[prop] == type` and updates the type accordingly.
 */
export function hasType<
	P extends PropertyKey,
	T extends keyof TypeOfTags,
>(
	obj: object,
	prop: P,
	type: T,
): obj is {[prop in P]: TypeOfTags[T]} {
	return typeof (obj as any)[prop] == type;
}

/** Check if `obj` has a property `prop` of type `type`.
 *
 * Effectively checks of `obj[prop] instanceof type` and updates the type accordingly.
 */
export function hasObj<
	P extends PropertyKey,
	F extends abstract new (...args: any[]) => any,
>(
	obj: object,
	prop: P,
	type: F,
): obj is {[prop in P]: InstanceType<F>} {
	return (obj as any)[prop] instanceof type;
}
