<script lang="ts">
	import Menu from "./Menu.svelte";
	import MenuAction from "./MenuAction.svelte";

	type T = $$Generic;
	interface $$Slots {
		item: {
			item: T,
		},
		default: Record<string, never>,
	}
	export let items: T[];
</script>

{#if items.length > 0}
	<Menu>
	{#each items as item}
		<MenuAction><slot name=item {item} /></MenuAction>
	{/each}
	</Menu>
{:else}
	<slot/>
{/if}
