import { EMPTY_ARRAY, err, hasObj, hasType } from "./utils";

export const VERSION = 0;

/** The lowest version that can read recipes that we write.
 *
 * Older versions should display a warning and be prepared to read unexpected data.
 *
 * This version will generally be bumped on backwards incompatible changes but will not be bumped for added data unless that new data is somehow determined to be essential to the recipe.
 */
export const VERSION_READ = 0;

/** The version of this recipe.
 *
 * Older versions should display a warning before editing or saving.
 */
export const VERSION_WRITE = 0;

export const SCHEMA = "https://recipes.kevincox.ca/schema/v0" as const;

export interface Ingredient {
	readonly amount: string,
	readonly unit: string,
	readonly name: string,
}

export interface Step {
	readonly instruction: string,
}

export interface Recipe {
	readonly $schema: typeof SCHEMA,
	readonly version_read: number,
	readonly version_write: number,

	readonly title: string,
	readonly servings: number,
	readonly ingredients: readonly Ingredient[],
	readonly steps: readonly Step[],
}

const EMPTY_RECIPE: Recipe = {
	$schema: SCHEMA,
	version_read: VERSION_READ,
	version_write: VERSION_WRITE,

	title: "Untitled",
	servings: 1,
	ingredients: EMPTY_ARRAY,
	steps: EMPTY_ARRAY,
};

export function hydrateRecipe(data: unknown): Recipe {
	if (typeof data != "object") return EMPTY_RECIPE;
	if (!data) return EMPTY_RECIPE;

	return {
		$schema: SCHEMA,
		version_read: hasType(data, "version_read", "number")? data.version_read : 0,
		version_write: hasType(data, "version_write", "number")? data.version_write : 0,

		title: hasType(data, "title", "string")? data.title : EMPTY_RECIPE.title,
		servings: hasType(data, "servings", "number")? data.servings : EMPTY_RECIPE.servings,
		ingredients: hasObj(data, "ingredients", Array)? data.ingredients.map(hydrateIngredient) : EMPTY_ARRAY,
		steps: hasObj(data, "steps", Array)? data.steps.map(hydrateStep) : EMPTY_ARRAY,
	};
}

const EMPTY_INGREDIENT: Ingredient = {
	amount: "1",
	unit: "",
	name: "Unnamed Ingredient",
};

export function hydrateIngredient(data: unknown): Ingredient {
	if (typeof data != "object") return EMPTY_INGREDIENT;
	if (!data) return EMPTY_INGREDIENT;

	return {
		amount: hasType(data, "amount", "string")? data.amount : EMPTY_INGREDIENT.amount,
		unit: hasType(data, "unit", "string")? data.unit : EMPTY_INGREDIENT.unit,
		name: hasType(data, "name", "string")? data.name : EMPTY_INGREDIENT.name,
	};
}

const EMPTY_STEP: Step = {
	instruction: "No instruction provided",
};

export function hydrateStep(data: unknown): Step {
	if (typeof data != "object") return EMPTY_STEP;
	if (!data) return EMPTY_STEP;

	return {
		instruction: hasType(data, "instruction", "string") ? data.instruction : EMPTY_STEP.instruction,
	};
}

export function deserializeRecipe(json: string): Recipe {
	let data = JSON.parse(json) as unknown;

	if (typeof data != "object"
		|| !data
		|| !hasType(data, "$schema", "string")
		|| data.$schema !== SCHEMA
	) {
		throw err`File does not appear to be a recipe.`;
	}

	return hydrateRecipe(data);
}

export function canReadRecipe(recipe: Recipe): boolean {
	return recipe.version_read <= VERSION;
}

export function canWriteRecipe(recipe: Recipe): boolean {
	return recipe.version_write <= VERSION;
}
