<script lang="ts">
	import { ready } from "@roxi/routify";
	
	import {db} from "../account";
	import Card from "../Card.svelte";
	import {floatingActionButton} from "../floatingactionbutton";
	import LinkList from "../LinkList.svelte";
	import {privateRecipeUrl} from "../urls";

	$: listing = $db && $db.list();
	$: if (listing) void listing.then($ready);
</script>

{#if listing}
	{#await listing then recipes}
		<Card>
			<LinkList items={recipes}>
				<a
					slot=item let:item={recipe}
					href={privateRecipeUrl(recipe.id)}
					>
					{recipe.title}
				</a>
				<p>No recipes.</p>
			</LinkList>
		</Card>
	{:catch e}
		Error: {e}
	{/await}
	<a
		use:floatingActionButton
		href="/new"
		title="Add new recipe"
		aria-label="Add new recipe"
		>
		+
	</a>
{/if}

<!-- routify:options preload=true -->
