<script lang="ts">
	import {ripple} from "./ripple";
</script>

<li use:ripple><slot /></li>

<style lang="scss">
li {
	display: flex;
	margin: 0;

	&:hover {
		background: #0001;
	}
}

li > :global(*) {
	display: flex;
	flex: 1;
	padding: 0.4em 0.8em;
}
</style>
