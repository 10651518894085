<ul><slot/></ul>

<style>
ul {
	display: flex;
	flex-flow: column;
	min-width: max-content;
	margin: 0;
	padding: 0;
	list-style: none;
}
</style>
