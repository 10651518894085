
/**
 * @roxi/routify 2.18.15
 * File generated Sat Aug 24 2024 03:15:50 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.15"
export const __timestamp = "2024-08-24T03:15:50.961Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import _index from '../src/pages/index.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "ownMeta": {
        "preload": true
      },
      "meta": {
        "preload": true,
        "recursive": true,
        "prerender": true
      },
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "isPage": true,
      "path": "/new",
      "id": "_new",
      "component": () => import('../src/pages/new.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/oauth",
      "id": "_oauth",
      "component": () => import('../src/pages/oauth.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/private/:id",
          "id": "_private__id",
          "component": () => import('../src/pages/private/[id].svelte').then(m => m.default)
        }
      ],
      "path": "/private"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

