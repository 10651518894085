import RemoteStorage from "remotestoragejs";
import type { AuthorizeOptions } from "remotestoragejs/release/types/interfaces/authorize_options.js";
import * as store from "svelte/store";

import RecipeDb from "./db.js";

class MyRemoteStorage extends RemoteStorage {
	authorize(options: AuthorizeOptions) {
		this.access.setStorageType(this.remote.storageApi);
		if (typeof options.scope === "undefined") {
			options.scope = this.access.scopeParameter;
		}

		let uri = `${location.pathname}${location.search}${location.hash}`;
		options.redirectUri = `${location.origin}/oauth#!${encodeURI(uri)}`;

		// This is required by some providers.
		options.clientId ||= location.host;

		RemoteStorage.Authorize.authorize(this, options);
	}
}

export const remoteStorage = new MyRemoteStorage({
	logging: false,
});

remoteStorage.setApiKeys({
	dropbox: "ukxyxeu7tlyruik",
	googledrive: "658304439638-fmguup0mgvusar3gaimpeo2vn7tb7nif.apps.googleusercontent.com",
});

remoteStorage.access.claim("ca.kevincox.recipes", "rw");

export const db = store.readable<RecipeDb | false | undefined>(undefined, set => {
	remoteStorage.on("connected", () => {
		set(new RecipeDb(remoteStorage));
	});

	remoteStorage.on("not-connected", () => {
		set(false);
	});
});
