import type {ActionReturn} from "svelte/types/runtime/action";

import css from "./ripple.module.scss";
import { EMPTY_OBJECT } from "./utils";

export let rippleHostClass: string = css.host;

function removeAnimationTarget(e: AnimationPlaybackEvent) {
	let animation = e.target as Animation;
	let effect = animation.effect as KeyframeEffect;
	effect.target!.remove();
}

export function rippleAt(
	el: HTMLElement,
	x: number,
	y: number,
): void {
	let r = document.createElement("div");
	r.className = css.ripple;
	r.ariaHidden = "true";

	let br = el.getBoundingClientRect();
	x -= br.left;
	y -= br.top;

	r.style.setProperty("--x", `${x}px`);
	r.style.setProperty("--y", `${y}px`);

	let a = r.animate({
		opacity: [0.4, 0],
		transform: ["scale(0)", "scale(1)"],
	}, 800);
	a.addEventListener("finish", removeAnimationTarget);

	el.appendChild(r);
}

export function rippleMouseDown(e: MouseEvent): void {
	rippleAt(e.currentTarget as HTMLElement, e.clientX, e.clientY);
}

export function ripple(node: HTMLElement): ActionReturn {
	node.addEventListener("mousedown", rippleMouseDown);
	node.classList.add(rippleHostClass);
	return EMPTY_OBJECT;
}
