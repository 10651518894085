<script lang="ts">
	import type * as CSS from "csstype";

	export let padding: CSS.Properties["padding"] = undefined;
</script>

<div class=c style:padding>
	{#if $$slots.header}
		<div class=h><slot name=header/></div>
	{/if}
	<slot/>
</div>

<style lang="scss">
.c {
	display: flex;
	flex-flow: column;

	padding: 1em;
	gap: 1em;
	overflow: auto;

	background: #FFF;
	box-shadow: 0 4px 16px 0 #0004;

	> :global(*) {
		margin: 0;
	}

	.h {
		margin: -1em -1em 1em;

		&:empty {
			display: none;
		}
	}
}
</style>
