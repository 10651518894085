import type RemoteStorage from "remotestoragejs";
import type BaseClient from "remotestoragejs/release/types/baseclient";

import { hydrateRecipe, type Recipe } from "./recipe";
import { err } from "./utils";

export interface RecipeId { readonly RecipeIdTag: unique symbol }

function wrapRecipeId(id: string): RecipeId {
	return id as any as RecipeId;
}

function unwrapRecipeId(id: RecipeId): string {
	return id as any as string;
}

function titleToId(title: string): RecipeId {
	return wrapRecipeId(
		encodeURIComponent(title)
			.replaceAll("%20", " "));
}

export function idToTitle(id: RecipeId): string {
	return decodeURIComponent(unwrapRecipeId(id));
}

export function urlEncodeRecipeId(id: RecipeId): string {
	// Since recipe IDs are already URL safe except for spaces do a partial URL encode.
	// We only need to encode space, which becomes %20, which requires encoding %20 as %25, and %25 as %2525.
	return unwrapRecipeId(id).replace(/ |%2[05]/g, encodeURIComponent);
}

export function urlDecodeRecipeId(id: string): RecipeId {
	return wrapRecipeId(id.replace(/%2[05]/g, decodeURIComponent));
}

export interface RecipeListingEntry {
	id: RecipeId,
	title: string,
}

export type RecipeListing = RecipeListingEntry[];

export default class RecipeDb {
	private client: BaseClient;

	constructor(
		client: RemoteStorage,
	) {
		this.client = client.scope("/ca.kevincox.recipes/");
		console.log("remote storage client", this.client);
	}

	async save(recipe: Recipe): Promise<RecipeId> {
		console.log("saving", recipe);
		let url = titleToId(recipe.title);
		await this.client.storeFile(
			"application/json",
			`recipes/${unwrapRecipeId(url)}/recipe.json`,
			JSON.stringify(recipe));
		return url;
	}

	async delete(id: RecipeId): Promise<void> {
		console.log("deleting", id);
		let r = await this.client.remove(`recipes/${unwrapRecipeId(id)}/`);
		console.log("deleted", id, r);
	}

	async flush(id: RecipeId): Promise<void> {
		try {
			await this.client.flush(`recipes/${unwrapRecipeId(id)}/`);
		} catch (e) {
			console.warn("flush failed", e);
		}
	}

	async load(id: RecipeId): Promise<Recipe> {
		console.log("Loading", id);
		let r = await this.client.getObject(`recipes/${unwrapRecipeId(id)}/recipe.json`);
		console.log("Loaded", id, r);
		if (!r) {
			throw err`Recipe not found.`;
		}
		return hydrateRecipe(r);
	}

	async list(): Promise<RecipeListing> {
		let listing = await this.client.getListing("recipes/");
		let r = Object.keys(listing as Record<string, unknown>)
			.filter(name => name.endsWith("/"))
			.map(name => {
				let url = name.slice(0, -1);
				let id = wrapRecipeId(url);
				return {
					id,
					title: idToTitle(id),
				};
			});
		r.sort((a, b) => a.title < b.title ? -1 : 1);
		return r;
	}
}
